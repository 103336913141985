import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Row, Col, Label, Table } from 'reactstrap';

import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';

// tslint:disable-next-line:no-unused-variable
import {
  Translate,
  translate,
  ICrudGetAction,
  ICrudSearchAction,
  ICrudGetAllAction,
  TextFormat,
  getSortState,
  setFileData,
  openFile,
  byteSize,
  ICrudPutAction,
  JhiPagination,
  IPaginationBaseState,
  getPaginationItemsNumber
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, setBlob, reset, searchFiles } from './file-import-task.reducer';
import { IFileImportTask } from 'app/shared/model/file-import-task.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
// tslint:disable-next-line:no-submodule-imports
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
// import { toast } from 'react-toastify';
import { getSharingModes, reset as resetSharingMode } from 'app/entities/sharing-mode/sharing-mode.reducer';
import { DocMetas } from 'app/entities/doc-metas/doc-metas.tsx';
import { getSearchEntities, getEntities, extractExcel } from '../doc-metas/doc-metas.reducer';
import { IDocMetas } from 'app/shared/model/doc-metas.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { Collapse } from 'react-collapse';

import { toast } from 'react-toastify';

import { Dropdown } from 'semantic-ui-react';
// tslint:disable-next-line:no-submodule-imports
import 'semantic-ui-css/semantic.min.css';

export interface IDocMetasProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IFileImportTaskUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IFileImportTaskUpdateState {
  isNew: boolean;
  selectedSharingModes: string[];
  files: [];
  search: string;
  docMetaData: ReadonlyArray<IDocMetas>;
}

export interface IDocMetasState extends IPaginationBaseState, RouteComponentProps<{ url: string }> {
  search: string;
  docMetaData: ReadonlyArray<IDocMetas>;
  page: number;
}

let showDocTable = true;
export class FileImportTaskUpdate extends React.Component<IFileImportTaskUpdateProps, IFileImportTaskUpdateState, IDocMetasState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      selectedSharingModes: null,
      files: [],
      search: '',
      docMetaData: [],
      page: 1,
      ...getSortState(this.props.location, ITEMS_PER_PAGE)
    };

    showDocTable = true;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    this.setState(
      {
        selectedSharingModes: null
      },
      () => {
        if (this.state.isNew) {
          this.props.reset();
        } else {
          this.props.getEntity(this.props.match.params.id);
        }
      }
    );

    this.props.searchFiles('');
    this.props.getSharingModes('');
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { fileImportTaskEntity } = this.props;
      const paramsData = this.getTagsParam();
      let search = '';
      this.state.files.map(file => {
        const content = this.readFileDataAsBase64(file['file']).then(data => {
          const contentData = typeof data === 'string' ? data.split('base64,')[1] : '';
          const entity = {
            id: null,
            content: contentData,
            contentContentType: file['file']['type'],
            fileName: file['file']['name'],
            params: paramsData
          };

          if (this.state.isNew) {
            toast.success(translate('frontlinemediaApp.fileImportTask.info.integration'));

            this.props.createEntity(entity).then(response => {
              toast.success(translate('frontlinemediaApp.fileImportTask.info.integrationSuccess'));
            });

            showDocTable = true;
            search === '' ? (search = file['file']['name']) : (search = search + ',' + file['file']['name']);

            this.setState({ search, selectedSharingModes: null });

            this.search();
          } else {
            this.props.updateEntity(entity);
          }
        });
      });
    }
  };

  readFileDataAsBase64 = file => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = event => {
        resolve(event.target['result']);
      };

      reader.onerror = err => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  };

  getTagsParam = () => {
    const { selectedSharingModes } = this.state;
    return `sharingModes:${selectedSharingModes.join(' ')}`;
  };

  handleClose = () => {
    this.props.history.push('/entity/file-import-task');
  };

  getUploadParams = ({ meta }) => {
    const url = 'https://httpbin.org/post';
    return { url };
  };

  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file }, status, allFiles) => {
    this.setState({
      files: allFiles
    });
  };

  handleAdditionSharingMode = (e, { searchQuery, value }) => {
    this.setState({ selectedSharingModes: value });
  };

  handleInputSharingMode = query => {
    // console.log('input', query);
    this.props.getSharingModes(query);
  };

  handleDeleteSharingMode = tag => {
    // console.log('delete', tag);
  };

  search = () => {
    this.setState({ activePage: 1, docMetaData: [] }, () => {
      // const { activePage, itemsPerPage, sort, order, search } = this.state;
      const activePage = this.state['activePage'];
      const itemsPerPage = this.state['itemsPerPage'];
      const sort = this.state['sort'];
      const order = this.state['order'];
      const search = encodeURI(this.state['search']);
      this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
    });
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  setInitialDocMet = docMetasList => {
    const docMetasListNew = this.getSplitedData(docMetasList);
    this.setState({
      docMetaData: docMetasListNew
    });
  };

  getSplitedData = docMetasList => {
    docMetasList.forEach(element => {
      if (
        (typeof element.persons === 'string' || element.persons instanceof String) &&
        (typeof element.countries === 'string' || element.countries instanceof String)
      ) {
        element.date = element.date.split(',');
        element.persons = element.persons.split(',');
        element.user = element.user.split(',');
        element.countries = element.countries.split(',');
        element.organisations = element.organisations.split(',');
        element.url = element.url.split(',');
        element['isOpened'] = !!element['isOpened'];
      }
    });

    return docMetasList;
  };

  clear = () => {
    this.setState({ search: '', activePage: 1 }, () => {
      this.props.getEntities();
    });
  };

  handleSearch = event => this.setState({ search: event.target.value });

  sort = prop => () => {
    this.setState(
      {
        order: this.state['order'] === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(
      `${this.props.location.pathname}?page=${this.state['activePage']}&sort=${this.state['sort']},${this.state['order']}`
    );
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const activePage = this.state['activePage'];
    const itemsPerPage = this.state['itemsPerPage'];
    const sort = this.state['sort'];
    const order = this.state['order'];
    const search = this.state['search'];
    if (search) {
      this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
    } else {
      this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  docMetasIsOpen = docMetas => {
    let docMetaData = this.state.docMetaData;
    const findResult = docMetaData.indexOf(docMetas);
    if (findResult !== -1) {
      docMetaData[findResult]['isOpened'] = !docMetaData[findResult]['isOpened'];
    }

    docMetaData = docMetaData;

    this.setState({ docMetaData });
  };

  getExcel = () => {
    this.props.extractExcel({ fileName: this.state.search });
  };

  getReload = () => {
    window.location.reload();
  };

  componentDidUpdate(prevProps, prevState) {
    const { docMetasList } = this.props;
    const { docMetaData } = this.state;
    if ((docMetaData.length === 0 && docMetasList.length > 0) || docMetasList.length !== docMetaData.length) {
      this.setInitialDocMet(docMetasList);
    }
  }

  _goToImport = () => {
    this.setState({ page: 1 });
  };

  _goToDataAnalysis = () => {
    this.setState({ page: 2 });
  };

  _handleSearchDropdown = (e, { searchQuery, value }) => {
    this.setState({ search: value.join(',') });
  };

  _handleSearchChange = (e, { searchQuery }) => {
    // this.props.searchFiles(searchQuery);
  };

  render() {
    const { fileImportTaskEntity, loading, updating, sharingModes, files, loadingFiles } = this.props;
    let { selectedSharingModes } = this.state;
    const { isNew, page, search } = this.state;
    const { content, contentContentType } = fileImportTaskEntity;

    if (!selectedSharingModes) {
      selectedSharingModes = [];

      if (fileImportTaskEntity && fileImportTaskEntity.params) {
        const strExtra = fileImportTaskEntity.params;
        const strSharingMode = strExtra.split(':');

        selectedSharingModes = strSharingMode[1].trim() !== '' ? strSharingMode[1].split(' ') : [];
        this.setState({ selectedSharingModes });
      }
    }

    let suggestionsSharingModes = [];

    suggestionsSharingModes = sharingModes.filter(sharingMode => selectedSharingModes.indexOf(sharingMode) === -1);

    suggestionsSharingModes = suggestionsSharingModes.map(sharingMode => ({ value: sharingMode, label: sharingMode }));

    const { docMetasList, match, totalItems } = this.props;
    const { docMetaData } = this.state;
    // if ((docMetaData.length == 0 && docMetasList.length > 0) || docMetasList.length != docMetaData.length) {
    //   this.setInitialDocMet(docMetasList);
    // }

    const docMetasOptions = files.map(docMeta => ({
      key: docMeta.id,
      text: docMeta.name,
      value: docMeta.name
    }));

    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="2">
            <div className="side-link" onClick={this._goToImport}>
              <Translate contentKey="frontlinemediaApp.fileImportTask.home.importLabel" />
            </div>

            <div className="side-link" onClick={this._goToDataAnalysis}>
              <Translate contentKey="frontlinemediaApp.fileImportTask.home.dataAnalysisLabel" />
            </div>
          </Col>

          <Col md="10">
            {page === 1 && (
              <div>
                <Row className="justify-content-center">
                  <Col md="12">
                    <h2 id="frontlinemediaApp.fileImportTask.home.createOrEditLabel">
                      <Translate contentKey="frontlinemediaApp.fileImportTask.home.importLabel" />
                    </h2>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md="12">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <AvForm model={isNew ? {} : fileImportTaskEntity} onSubmit={this.saveEntity}>
                        <AvGroup>
                          <AvGroup>
                            <Label id="contentLabel" for="content">
                              <Translate contentKey="frontlinemediaApp.fileImportTask.selectFiles">
                                Select the files to integrate{' '}
                              </Translate>
                            </Label>
                            <br />

                            <Dropzone
                              getUploadParams={this.getUploadParams}
                              onChangeStatus={this.handleChangeStatus}
                              maxSizeBytes={50000000}
                              // onSubmit={this.handleSubmit}
                              accept=".pdf,.doc,.docx,.csv,.xlsx,.xls"
                            />
                          </AvGroup>
                        </AvGroup>
                        <AvGroup>
                          <Label id="paramsLabel" for="params">
                            <Translate contentKey="frontlinemediaApp.fileImportTask.sharingMode">Sharing Mode</Translate>
                          </Label>

                          <Dropdown
                            placeholder={translate('frontlinemediaApp.chart.sharingMode')}
                            fluid
                            multiple
                            search
                            selection
                            closeOnChange
                            options={sharingModes.map(sharingMode => ({
                              key: sharingMode,
                              text: sharingMode,
                              value: sharingMode
                            }))}
                            onChange={this.handleAdditionSharingMode}
                            value={selectedSharingModes}
                          />
                        </AvGroup>
                        &nbsp;
                        <Button color="primary" id="save-entity" type="submit">
                          <FontAwesomeIcon icon="save" />
                          &nbsp;
                          <Translate contentKey="frontlinemediaApp.fileImportTask.integrate">Int�grer</Translate>
                        </Button>
                      </AvForm>
                    )}
                  </Col>
                </Row>
              </div>
            )}

            {page === 2 && (
              <div>
                <Row className="justify-content-center" style={{ marginBottom: 8 }}>
                  <Col md="12">
                    <h2 id="frontlinemediaApp.fileImportTask.home.createOrEditLabel">
                      <Translate contentKey="frontlinemediaApp.fileImportTask.home.dataAnalysisLabel" />
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <AvForm onSubmit={this.search}>
                      <AvGroup>
                        <InputGroup>
                          <Dropdown
                            placeholder={translate('frontlinemediaApp.docMetas.home.search')}
                            fluid
                            multiple
                            search
                            selection
                            closeOnChange
                            loading={loadingFiles}
                            options={docMetasOptions}
                            onChange={this._handleSearchDropdown}
                            onSearchChange={this._handleSearchChange}
                            value={search.split(',')}
                            className="search-dropdown"
                          />

                          <Button className="input-group-addon">
                            <FontAwesomeIcon icon="search" />
                          </Button>
                          <Button type="reset" className="input-group-addon" onClick={this.clear}>
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </InputGroup>
                      </AvGroup>
                    </AvForm>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table responsive className="table-layout table-bordered">
                    <thead>
                      <tr>
                        <th className="hand">
                          <Translate contentKey="frontlinemediaApp.docMetas.fileName">File Name</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="frontlinemediaApp.docMetas.date">Date</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="frontlinemediaApp.docMetas.persons">Persons</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="frontlinemediaApp.docMetas.countries">Countries</Translate>
                        </th>
                        <th className="hand">
                          <Translate contentKey="frontlinemediaApp.docMetas.organisations">Organisations</Translate>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {docMetaData.map((docMetas, i) => (
                        <tr key={`entity-${i}`}>
                          <td className="wordBreak">
                            <div className="displayInline">
                              <input type="checkbox" checked={docMetas['isOpened']} onChange={this.docMetasIsOpen.bind(this, docMetas)} />
                              <div>
                                <span>{docMetas.fileName}</span>
                              </div>
                              {/* <Collapse isOpened={docMetas['isOpened']}>
                            <div><span>{docMetas.fileName}</span></div>
                          </Collapse> */}
                            </div>
                          </td>
                          <td className="wordBreak">
                            <div>
                              <div>
                                <span>
                                  <TextFormat type="date" value={docMetas.date[0]} format={APP_DATE_FORMAT} />
                                </span>
                              </div>
                              {Array.isArray(docMetas.date) &&
                                docMetas.date.map((date, index) => (
                                  <div key={`entity-${date}-${index}`}>
                                    {index !== 0 && (
                                      <div>
                                        <Collapse isOpened={docMetas['isOpened']}>
                                          <span>
                                            <TextFormat type="date" value={date} format={APP_DATE_FORMAT} />
                                          </span>
                                        </Collapse>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </td>
                          <td className="wordBreak">
                            <div>
                              <div>
                                <span>{docMetas.persons[0]}</span>
                              </div>
                              {Array.isArray(docMetas.persons) &&
                                docMetas.persons.map((person, index) => (
                                  <div key={`entity-${person}-${index}`}>
                                    {index !== 0 && (
                                      <Collapse isOpened={docMetas['isOpened']}>
                                        <div>
                                          <span>{person}</span>
                                        </div>
                                      </Collapse>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </td>
                          <td className="wordBreak">
                            <div>
                              <div>
                                <span>{docMetas.countries[0]}</span>
                              </div>
                              {Array.isArray(docMetas.countries) &&
                                docMetas.countries.map((countrie, index) => (
                                  <div key={`entity-${countrie}-${index}`}>
                                    {index !== 0 && (
                                      <div>
                                        <Collapse isOpened={docMetas['isOpened']}>
                                          <span>{countrie}</span>
                                        </Collapse>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </td>
                          <td className="wordBreak">
                            <div>
                              <div>
                                <span>{docMetas.organisations[0]}</span>
                              </div>
                              {Array.isArray(docMetas.organisations) &&
                                docMetas.organisations.map((organisation, index) => (
                                  <div key={`entity-${organisation}-${index}`}>
                                    {index !== 0 && (
                                      <div>
                                        <Collapse isOpened={docMetas['isOpened']}>
                                          <span>{organisation}</span>
                                        </Collapse>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Row className="justify-content-center">
                  <JhiPagination
                    items={getPaginationItemsNumber(totalItems, this.state['itemsPerPage'])}
                    activePage={this.state['activePage']}
                    onSelect={this.handlePagination}
                    maxButtons={5}
                  />
                </Row>
                <Row className="justify-content-center">
                  <Button onClick={this.getExcel}>
                    <Translate contentKey="frontlinemediaApp.docMetas.ExtractAsExcel">Extract as Excel</Translate>
                  </Button>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  fileImportTaskEntity: storeState.fileImportTask.entity,
  loading: storeState.fileImportTask.loading,
  updating: storeState.fileImportTask.updating,
  updateSuccess: storeState.fileImportTask.updateSuccess,
  sharingModes: storeState.sharingMode.entities,
  docMetasList: storeState.docMetas.entities,
  totalItems: storeState.docMetas.totalItems,
  files: storeState.fileImportTask.files,
  loadingFiles: storeState.fileImportTask.loadingFiles
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getSharingModes,
  reset,
  getSearchEntities,
  getEntities,
  extractExcel,
  searchFiles
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileImportTaskUpdate);
