import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IChart, defaultValue, IDataSet } from 'app/shared/model/chart.model';

export const ACTION_TYPES = {
  SEARCH_CHARTS: 'chart/SEARCH_CHARTS',
  FETCH_CHART_LIST: 'chart/FETCH_CHART_LIST',
  FETCH_CHART: 'chart/FETCH_CHART',
  CREATE_CHART: 'chart/CREATE_CHART',
  UPDATE_CHART: 'chart/UPDATE_CHART',
  DELETE_CHART: 'chart/DELETE_CHART',
  SET_BLOB: 'chart/SET_BLOB',
  FETCH_DATASETS: 'chart/FETCH_DATASETS',
  FETCH_DYNAMIC_FORMS: 'chart/FETCH_DYNAMIC_FORMS',
  RESET: 'chart/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IChart>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  datasets: [] as ReadonlyArray<IDataSet>,
  dynamicForms: [] as ReadonlyArray<any>
};

export type ChartState = Readonly<typeof initialState>;

// Reducer

export default (state: ChartState = initialState, action): ChartState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_CHARTS):
    case REQUEST(ACTION_TYPES.FETCH_CHART_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CHART):
    case REQUEST(ACTION_TYPES.FETCH_DATASETS):
    case REQUEST(ACTION_TYPES.FETCH_DYNAMIC_FORMS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CHART):
    case REQUEST(ACTION_TYPES.UPDATE_CHART):
    case REQUEST(ACTION_TYPES.DELETE_CHART):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_CHARTS):
    case FAILURE(ACTION_TYPES.FETCH_CHART_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CHART):
    case FAILURE(ACTION_TYPES.CREATE_CHART):
    case FAILURE(ACTION_TYPES.UPDATE_CHART):
    case FAILURE(ACTION_TYPES.DELETE_CHART):
    case FAILURE(ACTION_TYPES.FETCH_DATASETS):
    case FAILURE(ACTION_TYPES.FETCH_DYNAMIC_FORMS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_CHARTS):
    case SUCCESS(ACTION_TYPES.FETCH_CHART_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CHART):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_DATASETS):
      return {
        ...state,
        loading: false,
        datasets: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_DYNAMIC_FORMS):
      return {
        ...state,
        loading: false,
        dynamicForms: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CHART):
    case SUCCESS(ACTION_TYPES.UPDATE_CHART):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CHART):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB:
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/charts';
const apiSearchUrl = 'api/_search/charts';
const apiSearchSharingModes = 'api/_search/sharing-modes';

// Actions

export const getSearchEntities: ICrudSearchAction<IChart> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_CHARTS,
  payload: axios.get<IChart>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IChart> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CHART_LIST,
    payload: axios.get<IChart>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: any = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CHART,
    payload: axios.get<IChart>(requestUrl)
  };
};

export const createEntity: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CHART,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const updateEntity: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CHART,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  // dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IChart> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CHART,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getDataSets: any = type => ({
  type: ACTION_TYPES.FETCH_DATASETS,
  payload: axios.get<IDataSet>(`api/chart-datasets/${type}`)
});

export const getDynamicForm: any = (type, datasetId) => ({
  type: ACTION_TYPES.FETCH_DYNAMIC_FORMS,
  payload: axios.get<any>(`api/chart-params/${type}/${datasetId}`)
});
