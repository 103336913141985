import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IDocMetas, defaultValue } from 'app/shared/model/doc-metas.model';

export const ACTION_TYPES = {
  SEARCH_DOCMETAS: 'docMetas/SEARCH_DOCMETAS',
  FETCH_DOCMETAS_LIST: 'docMetas/FETCH_DOCMETAS_LIST',
  FETCH_DOCMETAS: 'docMetas/FETCH_DOCMETAS',
  CREATE_DOCMETAS: 'docMetas/CREATE_DOCMETAS',
  UPDATE_DOCMETAS: 'docMetas/UPDATE_DOCMETAS',
  DELETE_DOCMETAS: 'docMetas/DELETE_DOCMETAS',
  RESET: 'docMetas/RESET',
  EXTRACT_EXCEL: 'docMetas/EXTRACT_EXCEL'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDocMetas>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type DocMetasState = Readonly<typeof initialState>;

// Reducer

export default (state: DocMetasState = initialState, action): DocMetasState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_DOCMETAS):
    case REQUEST(ACTION_TYPES.FETCH_DOCMETAS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DOCMETAS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DOCMETAS):
    case REQUEST(ACTION_TYPES.UPDATE_DOCMETAS):
    case REQUEST(ACTION_TYPES.DELETE_DOCMETAS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_DOCMETAS):
    case FAILURE(ACTION_TYPES.FETCH_DOCMETAS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DOCMETAS):
    case FAILURE(ACTION_TYPES.CREATE_DOCMETAS):
    case FAILURE(ACTION_TYPES.UPDATE_DOCMETAS):
    case FAILURE(ACTION_TYPES.DELETE_DOCMETAS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SEARCH_DOCMETAS):
    case SUCCESS(ACTION_TYPES.FETCH_DOCMETAS_LIST):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_DOCMETAS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DOCMETAS):
    case SUCCESS(ACTION_TYPES.UPDATE_DOCMETAS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_DOCMETAS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/doc-metas';
const apiSearchUrl = 'api/_search/doc-metas';

// Actions

export const getSearchEntities: ICrudSearchAction<IDocMetas> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_DOCMETAS,
  payload: axios.get<IDocMetas>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`)
});

export const getEntities: ICrudGetAllAction<IDocMetas> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DOCMETAS_LIST,
    payload: axios.get<IDocMetas>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IDocMetas> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DOCMETAS,
    payload: axios.get<IDocMetas>(requestUrl)
  };
};

export const extractExcel = entity => {
  const link = document.createElement('a');
  link.download = entity.fileName;
  link.href = `${apiUrl}/csv-download?query=${entity.fileName}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createEntity: ICrudPutAction<IDocMetas> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DOCMETAS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDocMetas> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DOCMETAS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDocMetas> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DOCMETAS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
