import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './role-groups.reducer';
import { IRoleGroups } from 'app/shared/model/role-groups.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IRoleGroupsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class RoleGroupsDetail extends React.Component<IRoleGroupsDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { roleGroupsEntity } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="frontlinemediaApp.roleGroups.detail.title">RoleGroups</Translate> [<b>{roleGroupsEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="role">
                  <Translate contentKey="frontlinemediaApp.roleGroups.role">Role</Translate>
                </span>
              </dt>
              <dd>{roleGroupsEntity.role}</dd>
              <dt>
                <span id="groups">
                  <Translate contentKey="frontlinemediaApp.roleGroups.groups">Groups</Translate>
                </span>
              </dt>
              <dd>{roleGroupsEntity.groups}</dd>
              <dt>
                <span id="activate">
                  <Translate contentKey="frontlinemediaApp.roleGroups.activate">Activate</Translate>
                </span>
              </dt>
              <dd>{roleGroupsEntity.activate ? 'true' : 'false'}</dd>
              <dt>
                <span id="params">
                  <Translate contentKey="frontlinemediaApp.roleGroups.params">Params</Translate>
                </span>
              </dt>
              <dd>{roleGroupsEntity.params}</dd>
            </dl>
            <Button tag={Link} to="/entity/role-groups" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/entity/role-groups/${roleGroupsEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ roleGroups }: IRootState) => ({
  roleGroupsEntity: roleGroups.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleGroupsDetail);
