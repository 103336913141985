import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DocMetas from './doc-metas';
import DocMetasDetail from './doc-metas-detail';
import DocMetasUpdate from './doc-metas-update';
import DocMetasDeleteDialog from './doc-metas-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DocMetasUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DocMetasUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DocMetasDetail} />
      <ErrorBoundaryRoute path={match.url} component={DocMetas} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={DocMetasDeleteDialog} />
  </>
);

export default Routes;
