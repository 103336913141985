import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TaskExecution from './task-execution';
import TaskExecutionDetail from './task-execution-detail';
import TaskExecutionUpdate from './task-execution-update';
import TaskExecutionDeleteDialog from './task-execution-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TaskExecutionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TaskExecutionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TaskExecutionDetail} />
      <ErrorBoundaryRoute path={match.url} component={TaskExecution} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={TaskExecutionDeleteDialog} />
  </>
);

export default Routes;
