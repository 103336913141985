import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, openFile, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './chart.reducer';
import { IChart } from 'app/shared/model/chart.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IChartDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ChartDetail extends React.Component<IChartDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { chartEntity } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="frontlinemediaApp.chart.detail.title">Chart</Translate> [<b>{chartEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="indexName">
                  <Translate contentKey="frontlinemediaApp.chart.indexName">Index Name</Translate>
                </span>
              </dt>
              <dd>{chartEntity.indexName}</dd>
              <dt>
                <span id="kibanaDocumentId">
                  <Translate contentKey="frontlinemediaApp.chart.kibanaDocumentId">Kibana Document Id</Translate>
                </span>
              </dt>
              <dd>{chartEntity.kibanaDocumentId}</dd>
              <dt>
                <span id="lastModificationDate">
                  <Translate contentKey="frontlinemediaApp.chart.lastModificationDate">Last Modification Date</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={chartEntity.lastModificationDate} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="lastRefreshDate">
                  <Translate contentKey="frontlinemediaApp.chart.lastRefreshDate">Last Refresh Date</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={chartEntity.lastRefreshDate} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="user">
                  <Translate contentKey="frontlinemediaApp.chart.user">User</Translate>
                </span>
              </dt>
              <dd>{chartEntity.user}</dd>
              <dt>
                <span id="imageContent">
                  <Translate contentKey="frontlinemediaApp.chart.imageContent">Image Content</Translate>
                </span>
              </dt>
              <dd>
                {chartEntity.imageContent ? (
                  <div>
                    <a onClick={openFile(chartEntity.imageContentContentType, chartEntity.imageContent)}>
                      <Translate contentKey="entity.action.open">Open</Translate>
                      &nbsp;
                    </a>
                    <span>
                      {chartEntity.imageContentContentType}, {byteSize(chartEntity.imageContent)}
                    </span>
                  </div>
                ) : null}
              </dd>
              <dt>
                <span id="type">
                  <Translate contentKey="frontlinemediaApp.chart.type">Type</Translate>
                </span>
              </dt>
              <dd>{chartEntity.type}</dd>
              <dt>
                <span id="params">
                  <Translate contentKey="frontlinemediaApp.chart.params">Params</Translate>
                </span>
              </dt>
              <dd>{chartEntity.params}</dd>
              <dt>
                <span id="privateMode">
                  <Translate contentKey="frontlinemediaApp.chart.privateMode">Private Mode</Translate>
                </span>
              </dt>
              <dd>{chartEntity.privateMode ? 'true' : 'false'}</dd>
              <dt>
                <span id="url">
                  <Translate contentKey="frontlinemediaApp.chart.url">Url</Translate>
                </span>
              </dt>
              <dd>{chartEntity.url}</dd>
              <dt>
                <span id="extra">
                  <Translate contentKey="frontlinemediaApp.chart.extra">Extra</Translate>
                </span>
              </dt>
              <dd>{chartEntity.extra}</dd>
            </dl>
            <Button tag={Link} to="/entity/chart" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/entity/chart/${chartEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ chart }: IRootState) => ({
  chartEntity: chart.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartDetail);
