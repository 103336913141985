import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Chart from './chart';
import ChartDetail from './chart-detail';
import ChartUpdate from './chart-update';
import ChartDeleteDialog from './chart-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ChartUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ChartUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ChartDetail} />
      <ErrorBoundaryRoute path={match.url} component={Chart} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={ChartDeleteDialog} />
  </>
);

export default Routes;
