import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './file-import-task.reducer';
import { IFileImportTask } from 'app/shared/model/file-import-task.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IFileImportTaskDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class FileImportTaskDetail extends React.Component<IFileImportTaskDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { fileImportTaskEntity } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="frontlinemediaApp.fileImportTask.detail.title">FileImportTask</Translate> [
              <b>{fileImportTaskEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="overridenIndexName">
                  <Translate contentKey="frontlinemediaApp.fileImportTask.overridenIndexName">Overriden Index Name</Translate>
                </span>
              </dt>
              <dd>{fileImportTaskEntity.overridenIndexName}</dd>
              <dt>
                <span id="overridenUser">
                  <Translate contentKey="frontlinemediaApp.fileImportTask.overridenUser">Overriden User</Translate>
                </span>
              </dt>
              <dd>{fileImportTaskEntity.overridenUser}</dd>
              <dt>
                <span id="content">
                  <Translate contentKey="frontlinemediaApp.fileImportTask.content">Content</Translate>
                </span>
              </dt>
              <dd>
                {fileImportTaskEntity.content ? (
                  <div>
                    <a onClick={openFile(fileImportTaskEntity.contentContentType, fileImportTaskEntity.content)}>
                      <Translate contentKey="entity.action.open">Open</Translate>
                      &nbsp;
                    </a>
                    <span>
                      {fileImportTaskEntity.contentContentType}, {byteSize(fileImportTaskEntity.content)}
                    </span>
                  </div>
                ) : null}
              </dd>
              <dt>
                <a href={`/api/file-download/${fileImportTaskEntity.id}`}>Telecharger</a>
                <span id="params">
                  <Translate contentKey="frontlinemediaApp.fileImportTask.params">Params</Translate>
                </span>
              </dt>
              <dd>{fileImportTaskEntity.params}</dd>
              <dt>
                <span id="fileName">
                  <Translate contentKey="frontlinemediaApp.fileImportTask.fileName">File Name</Translate>
                </span>
              </dt>
              <dd>{fileImportTaskEntity.fileName}</dd>
            </dl>
            <Button tag={Link} to="/entity/file-import-task" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/entity/file-import-task/${fileImportTaskEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ fileImportTask }: IRootState) => ({
  fileImportTaskEntity: fileImportTask.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileImportTaskDetail);
