import { Moment } from 'moment';

export interface IDocMetas {
  id?: number;
  fileName?: string;
  date?: Moment;
  user?: string;
  persons?: string;
  countries?: string;
  organisations?: string;
  url?: string;
  extras?: string;
}

export const defaultValue: Readonly<IDocMetas> = {};
