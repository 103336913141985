import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import FileImportTask from './file-import-task';
import FileImportTaskDetail from './file-import-task-detail';
import FileImportTaskUpdate from './file-import-task-update';
import FileImportTaskDeleteDialog from './file-import-task-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={FileImportTaskUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={FileImportTaskUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={FileImportTaskDetail} />
      <ErrorBoundaryRoute path={match.url} component={FileImportTask} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={FileImportTaskDeleteDialog} />
  </>
);

export default Routes;
