import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Button, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Translate, translate } from 'react-jhipster';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { saveAs } from 'file-saver';

export class ChartLoadingDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isOpen
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.isOpen && nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  _toggleModal = () => {
    const { urlImage, minimizable } = this.props;

    if (!minimizable) {
      this.props.minimizeModal(this.props.index, urlImage !== '');
    } else {
      this.props.minimizeModal(this.props.index, true);
    }
  };

  _downloadImage = () => {
    const { urlImage, chartTitle } = this.props;

    const urlImageSplitted = urlImage.split('.');

    const imageFormat = urlImageSplitted[urlImageSplitted.length - 1];

    saveAs(urlImage, chartTitle + '.' + imageFormat);
  };

  _renderModalContent() {
    const { urlImage } = this.props;

    if (!urlImage) {
      return (
        <div className="modal-loader">
          <Translate contentKey="frontlinemediaApp.chart.createLoading" />
          <img src="content/images/loader.gif" />
        </div>
      );
    } else {
      return (
        <Row className="justify-content-center">
          <Col md="12">
            <div className="chart-result-container">
              <div className="url-result">
                <div className="inline-block w400">
                  <Translate contentKey="frontlinemediaApp.chart.urlImage" />:
                </div>

                <div className="url-result-value" onClick={this._downloadImage}>
                  {urlImage}
                </div>

                <CopyToClipboard text={urlImage} onCopy={this.props.informCopy}>
                  <a className="url-result-action">
                    <Translate contentKey="frontlinemediaApp.chart.copyToClipboard" />
                  </a>
                </CopyToClipboard>

                <a onClick={this._downloadImage} className="url-result-action">
                  <Translate contentKey="frontlinemediaApp.chart.download" />
                </a>
              </div>

              <div className="image-result">
                <img src={urlImage} onClick={this._downloadImage} />
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }

  _minimize = index => {
    this.props.minimizeModal(index, true);
  };

  _close = index => {
    this.props.closeModal(index);
  };

  render() {
    const { isOpen, index, minimizable } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this._toggleModal} className="modal-chart-loading">
        {minimizable ? (
          <ModalHeader className="force-right">
            <Button color="info" className="undo-minimized" onClick={this._minimize.bind(this, index)}>
              Réduire
            </Button>
            <Button color="danger" className="undo-minimized" onClick={this._close.bind(this, index)}>
              Fermer
            </Button>
          </ModalHeader>
        ) : null}
        <ModalBody>{this._renderModalContent()}</ModalBody>
      </Modal>
    );
  }
}
