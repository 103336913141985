import { Moment } from 'moment';

export const enum TaskExecutionStatus {
  Running = 'Running',
  Ended = 'Ended',
  Cancelled = 'Cancelled',
  Idle = 'Idle',
  Enqueued = 'Enqueued'
}

export interface ITaskExecution {
  id?: number;
  taskId?: string;
  description?: string;
  executionStart?: Moment;
  executionEnd?: Moment;
  durationInSeconds?: number;
  status?: TaskExecutionStatus;
  statusMessage?: string;
}

export const defaultValue: Readonly<ITaskExecution> = {};
