import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './cms-task.reducer';
import { ICMSTask } from 'app/shared/model/cms-task.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICMSTaskDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CMSTaskDetail extends React.Component<ICMSTaskDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { cMSTaskEntity } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="frontlinemediaApp.cMSTask.detail.title">CMSTask</Translate> [<b>{cMSTaskEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="scheduledCrontab">
                  <Translate contentKey="frontlinemediaApp.cMSTask.scheduledCrontab">Scheduled Crontab</Translate>
                </span>
              </dt>
              <dd>{cMSTaskEntity.scheduledCrontab}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="frontlinemediaApp.cMSTask.description">Description</Translate>
                </span>
              </dt>
              <dd>{cMSTaskEntity.description}</dd>
              <dt>
                <span id="historyPeriodNumber">
                  <Translate contentKey="frontlinemediaApp.cMSTask.historyPeriodNumber">History Period Number</Translate>
                </span>
              </dt>
              <dd>{cMSTaskEntity.historyPeriodNumber}</dd>
              <dt>
                <span id="historyPeriodUnit">
                  <Translate contentKey="frontlinemediaApp.cMSTask.historyPeriodUnit">History Period Unit</Translate>
                </span>
              </dt>
              <dd>{cMSTaskEntity.historyPeriodUnit}</dd>
              <dt>
                <span id="activate">
                  <Translate contentKey="frontlinemediaApp.cMSTask.activate">Activate</Translate>
                </span>
              </dt>
              <dd>{cMSTaskEntity.activate ? 'true' : 'false'}</dd>
              <dt>
                <span id="whereClause">
                  <Translate contentKey="frontlinemediaApp.cMSTask.whereClause">Where Clause</Translate>
                </span>
              </dt>
              <dd>{cMSTaskEntity.whereClause}</dd>
            </dl>
            <Button tag={Link} to="/entity/cms-task" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/entity/cms-task/${cMSTaskEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ cMSTask }: IRootState) => ({
  cMSTaskEntity: cMSTask.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMSTaskDetail);
