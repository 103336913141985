import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, setFileData, openFile, byteSize, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, setBlob, reset, getDataSets, getDynamicForm } from './chart.reducer';
import { getSharingModes, reset as resetSharingMode } from 'app/entities/sharing-mode/sharing-mode.reducer';
import { IChart } from 'app/shared/model/chart.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

import { toast } from 'react-toastify';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ChartLoadingDialog } from './chart-loading-dialog';

import { Dropdown } from 'semantic-ui-react';
// tslint:disable-next-line:no-submodule-imports
import 'semantic-ui-css/semantic.min.css';

export interface IChartUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IChartUpdateState {
  isNew: boolean;
  selectedSharingModes: string[];
  type: string;
  dataset: any;
  params: string[];
  urlImage: string;
  creatingChart: boolean;
  createChartIsLoading: boolean;
  nChart: number;
  chartTitle: string;
  fieldTitle: string;
  fieldDescription: string;
  chartsInProgress: any[];
}

export class ChartUpdate extends React.Component<IChartUpdateProps, IChartUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      selectedSharingModes: null,
      type: null,
      dataset: null,
      params: [],
      urlImage: null,
      creatingChart: false,
      createChartIsLoading: true,
      nChart: -1,
      chartTitle: 'chart',
      fieldTitle: '',
      fieldDescription: '',
      chartsInProgress: []
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      // this.handleClose();
    }
  }

  componentDidMount() {
    this.props.reset();
    this.setState(
      {
        selectedSharingModes: null,
        type: null,
        dataset: null,
        params: [],
        urlImage: null
      },
      () => {
        if (this.state.isNew) {
          this.props.getDataSets('Pie').then(() => {
            this.props.getDynamicForm('Pie', this.props.datasets.length > 0 ? this.props.datasets[0].id : '');
          });
        } else {
          this.props.getEntity(this.props.match.params.id).then(() => {
            this.props.getDataSets(this.props.chartEntity.type).then(() => {
              this.props.getDynamicForm(this.props.chartEntity.type, this.props.chartEntity.indexName);
            });
          });
        }

        if (this.props.location.state) {
          const { urlImage } = this.props.location.state;
          this.setState({ urlImage });
        }

        this.props.getSharingModes('');
      }
    );
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    values.lastModificationDate = convertDateTimeToServer(values.lastModificationDate);
    values.lastRefreshDate = convertDateTimeToServer(values.lastRefreshDate);

    const { selectedSharingModes } = this.state;
    const { dynamicForms } = this.props;

    let strParams = '';

    dynamicForms.forEach(dynamicForm => {
      strParams += dynamicForm.key + ':' + values[dynamicForm.key] + ';';
    });

    if (errors.length === 0) {
      if (selectedSharingModes == null) {
        toast.error('Veuillez définir la valeur du champ Sharing mode.');
        return;
      }

      this.setState({ creatingChart: true });

      const { chartEntity } = this.props;

      const chartTitle = values.title;

      const chartDescription = values.description;

      delete values.title;

      delete values.description;

      const entity = {
        ...chartEntity,
        ...values,
        indexName: values.indexName ? values.indexName : null,
        kibanaDocumentId: null,
        user: null,
        imageContent: null,
        imageContentContentType: null,
        params: strParams,
        url: chartEntity.url ? chartEntity.url : null,
        extra: 'sharingModes:' + selectedSharingModes.join(' ') + ';title:' + chartTitle + ';description:' + chartDescription
      };

      const curNChart = this.state.nChart + 1;

      const { chartsInProgress } = this.state;

      chartsInProgress.push({
        loading: true,
        url: '',
        title: null,
        minimized: false
      });

      this.setState({ nChart: curNChart });

      if (this.state.isNew) {
        entity.id = null;

        this.props
          .createEntity(entity)
          .then(reponse => {
            // console.log('reponse', reponse.value);

            const newChartEntity = reponse.value.data;

            const curChartsInProgress = this.state.chartsInProgress;

            if (curChartsInProgress[curNChart]) {
              curChartsInProgress[curNChart].url = newChartEntity.url;
              curChartsInProgress[curNChart].title = newChartEntity.indexName;
              curChartsInProgress[curNChart].loading = false;

              this.setState({ chartsInProgress: curChartsInProgress });
            }
          })
          .catch(error => {
            toast.error(
              'Un problème est survenu durant la création du graphique. Veuillez re-essayer ou contacter l’administrateur du site'
            );
            this.setState({ creatingChart: false });
          });
      } else {
        try {
          this.setState({ urlImage: null });

          this.props
            .updateEntity(entity)
            .then(reponse => {
              // console.log('reponse', reponse.value);

              const newChartEntity = reponse.value.data;

              const curChartsInProgress = this.state.chartsInProgress;

              curChartsInProgress[curNChart].url = newChartEntity.url;
              curChartsInProgress[curNChart].title = newChartEntity.indexName;
              curChartsInProgress[curNChart].loading = false;

              this.setState({ chartsInProgress: curChartsInProgress });
            })
            .catch(error => {
              toast.error(
                'Un problème est survenu durant la modification du graphique. Veuillez re-essayer ou contacter l’administrateur du site'
              );
              this.setState({ creatingChart: false });
            });
        } catch (error) {}
      }
    } else {
      this.setState({ creatingChart: false });
    }
  };

  handleClose = () => {
    this.props.resetSharingMode();
    this.props.history.push('/entity/chart');
  };

  handleDeleteSharingMode = tag => {
    // console.log('delete', tag);
  };

  handleAdditionSharingMode = (e, { searchQuery, value }) => {
    this.setState({ selectedSharingModes: value });
  };

  handleInputSharingMode = query => {};

  handleChangetype = type => {
    // console.log('type', type.target.value);
    this.setState({ type: type.target.value });
    this.props.getDataSets(type.target.value).then(() => {
      this.handleChangeDataSet(this.props.datasets && this.props.datasets.length > 0 ? this.props.datasets[0] : null);
    });
  };

  _handleChangeTitle = title => {
    this.setState({ fieldTitle: title.target.value });
  };

  _handleChangeDescription = description => {
    this.setState({ fieldDescription: description.target.value });
  };

  handleChangeDataSet = dataset => {
    if (!dataset) {
      return;
    }

    const value = (dataset.target && dataset.target.value) || dataset.id;

    // console.log('dataset', value);

    const { type } = this.state;

    this.setState({ dataset: { id: value } });

    this.props.getDynamicForm(type, value);
  };

  _informCopy = () => {
    toast.info(translate('frontlinemediaApp.chart.copiedMessage'));
  };

  _toggleChartLoading = () => {
    this._resetForm();
  };

  _resetForm() {
    if (this.state.isNew) {
      this.props.reset();
      this.props.resetSharingMode();
      this.setState({
        selectedSharingModes: null,
        type: null,
        dataset: null,
        params: [],
        urlImage: null,
        creatingChart: false,
        fieldTitle: ''
      });
      this.props.getDataSets('Pie').then(() => {
        this.props.getDynamicForm('Pie', this.props.datasets.length > 0 ? this.props.datasets[0].id : '');
      });
    } else if (this.state.urlImage) {
      this.setState({
        creatingChart: false
      });
    }
  }

  _renderModalsResult() {
    const { chartsInProgress, isNew } = this.state;

    // console.log("chartsInProgress",chartsInProgress);

    return (
      <div className="window-backdrop">
        {chartsInProgress.map(
          (chart, i) =>
            chart != null ? (
              <ChartLoadingDialog
                key={i}
                index={i}
                isOpen={!chart.minimized}
                minimizable={isNew}
                urlImage={chart.url}
                minimizeModal={this._minimizeModal}
                closeModal={this._closeModal}
                informCopy={this._informCopy}
                chartTitle={chart.title}
              />
            ) : null
        )}
      </div>
    );
  }

  _renderMinimizedBar() {
    const { chartsInProgress } = this.state;

    return (
      <div className="minimized-bar">
        {chartsInProgress.filter(chart => chart != null && chart.minimized).map((chart, i) => (
          <div className="minimized-item" key={i}>
            <div className="maximizeZone" onClick={this._maximizeModal.bind(this, chart)}>
              {chart.title ? chart.title : 'Loading chart... [' + chartsInProgress.indexOf(chart) + ']'}
            </div>

            <div className="closeModal" onClick={this._closeModal.bind(this, chartsInProgress.indexOf(chart))}>
              <img src="content/images/close.png" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  _maximizeModal = chart => {
    const { chartsInProgress } = this.state;

    chartsInProgress[chartsInProgress.indexOf(chart)].minimized = false;

    this.setState({ chartsInProgress });
  };

  _minimizeModal = (i, minimizable) => {
    if (minimizable) {
      if (!this.state.isNew) {
        this.setState({ chartsInProgress: [], nChart: -1 });
      } else {
        const { chartsInProgress } = this.state;

        chartsInProgress[i].minimized = true;

        this.setState({ chartsInProgress, selectedSharingModes: null });
      }
    }
  };

  _closeModal = chartIndex => {
    const { chartsInProgress } = this.state;

    chartsInProgress[chartIndex] = null;

    this.setState({ chartsInProgress });
  };

  render() {
    // console.log('chart.props', this.props);
    // console.log('chart.state', this.state);
    const { loading, updating, datasets, dynamicForms, sharingModes } = this.props;
    const { isNew, urlImage, creatingChart, createChartIsLoading, chartTitle } = this.state;

    let { fieldTitle, fieldDescription } = this.state;

    let type = 'Pie';

    if (!isNew) {
      const { chartEntity } = this.props;
    }

    let selectedSharingModes = this.state.selectedSharingModes;
    let dataset = this.state.dataset;
    const dynamicFormValue = {};

    // @ts-ignore
    if (chartEntity && chartEntity.params) {
      const tabParams = chartEntity.params.split(';');

      tabParams.forEach(param => {
        const tabParam = param.split(':');

        if (
          dynamicForms.filter(dynamicForm => {
            return dynamicForm.key === tabParam[0];
          }).length === 1
        ) {
          dynamicFormValue[tabParam[0]] = tabParam[1];
        }
      });
    }

    if (this.state.type) {
      type = this.state.type;
    } else if (chartEntity) {
      type = chartEntity.type;
    }

    if (!dataset) {
      dataset =
        !isNew && chartEntity.indexName && chartEntity
          ? { id: chartEntity.indexName }
          : datasets && datasets.length > 0
            ? datasets[0]
            : null;
    }

    if (!selectedSharingModes) {
      selectedSharingModes = [];

      if (chartEntity && chartEntity.extra) {
        const strExtra = chartEntity.extra.split(';');
        const strSharingMode = strExtra[0].split(':');
        fieldTitle = strExtra.length >= 2 ? strExtra[1].split(':')[1] : '';
        fieldDescription = strExtra.length >= 3 ? strExtra[2].split(':')[1] : '';

        selectedSharingModes = strSharingMode[1].trim() !== '' ? strSharingMode[1].split(' ') : [];
        this.setState({ selectedSharingModes, fieldTitle, fieldDescription });
      }
    }

    let suggestionsSharingModes = [];

    suggestionsSharingModes = sharingModes.filter(sharingMode => selectedSharingModes.indexOf(sharingMode) === -1);

    suggestionsSharingModes = suggestionsSharingModes.map(sharingMode => ({ value: sharingMode, label: sharingMode }));

    if (chartEntity) {
      const { imageContent, imageContentContentType } = chartEntity;
      chartEntity.title = fieldTitle;
      chartEntity.description = fieldDescription;
    }

    return (
      <div className="padding-wrapper light-bg">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="frontlinemediaApp.chart.home.createOrEditLabel">
              <Translate contentKey="frontlinemediaApp.chart.home.createOrEditLabel">Create or edit a Chart</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? { title: fieldTitle, description: fieldDescription } : chartEntity} onSubmit={this.saveEntity}>
                <AvGroup>
                  <Label id="titleLabel">
                    <Translate contentKey="frontlinemediaApp.chart.home.titleLabel">Titre</Translate>
                  </Label>
                  <AvInput id="chart-title" type="text" className="form-control" name="title" onChange={this._handleChangeTitle} />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel">
                    <Translate contentKey="frontlinemediaApp.chart.home.descriptionLabel">Description</Translate>
                  </Label>
                  <AvInput
                    id="chart-description"
                    type="text"
                    className="form-control"
                    name="description"
                    onChange={this._handleChangeDescription}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="typeLabel">
                    <Translate contentKey="frontlinemediaApp.chart.type">Type</Translate>
                  </Label>
                  <AvInput id="chart-type" type="select" className="form-control" name="type" value={type} onChange={this.handleChangetype}>
                    <option value="Pie">{translate('frontlinemediaApp.ChartType.Pie')}</option>
                    <option value="Bar">{translate('frontlinemediaApp.ChartType.Bar')}</option>
                    <option value="Map">{translate('frontlinemediaApp.ChartType.Map')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="typeLabel">
                    <Translate contentKey="frontlinemediaApp.chart.dataSet">Data set</Translate>
                  </Label>
                  <AvInput
                    id="chart-dataset"
                    type="select"
                    className="form-control"
                    name="indexName"
                    value={dataset ? dataset.id : null}
                    onChange={this.handleChangeDataSet}
                  >
                    {datasets
                      ? datasets.map(datasetItem => (
                          <option value={datasetItem.id} key={datasetItem.id}>
                            {datasetItem.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="paramsLabel" for="params">
                    <Translate contentKey="frontlinemediaApp.chart.parameters">Parameters</Translate>
                  </Label>

                  {dynamicForms
                    ? dynamicForms.map(dynamicForm => (
                        <AvGroup className="params-group" key={dynamicForm.key}>
                          <Label for={dynamicForm.key}>{translate(`frontlinemediaApp.chart.formComponent.${dynamicForm.key}`)}</Label>
                          <AvInput
                            type="select"
                            className="form-control"
                            name={dynamicForm.key}
                            value={
                              dynamicFormValue && dynamicFormValue[dynamicForm.key]
                                ? dynamicFormValue[dynamicForm.key]
                                : dynamicForm.values
                                  ? dynamicForm.values.split(',')[0]
                                  : null
                            }
                          >
                            {dynamicForm.values
                              ? dynamicForm.values.split(',').map(opt => (
                                  <option value={opt} key={opt}>
                                    {opt}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                        </AvGroup>
                      ))
                    : null}
                </AvGroup>
                <AvGroup>
                  <Label id="paramsLabel" for="sharingMode">
                    <Translate contentKey="frontlinemediaApp.chart.sharingMode">Sharing mode</Translate>
                  </Label>
                  <Dropdown
                    placeholder={translate('frontlinemediaApp.chart.sharingMode')}
                    fluid
                    multiple
                    search
                    selection
                    closeOnChange
                    options={sharingModes.map(sharingMode => ({
                      key: sharingMode,
                      text: sharingMode,
                      value: sharingMode
                    }))}
                    onChange={this.handleAdditionSharingMode}
                    value={selectedSharingModes}
                  />
                </AvGroup>
                <Button id="cancel-save" onClick={this.handleClose} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit">
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="frontlinemediaApp.chart.save">Créer graphique</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>

        {this._renderModalsResult()}

        {this._renderMinimizedBar()}
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  chartEntity: storeState.chart.entity,
  loading: storeState.chart.loading,
  updating: storeState.chart.updating,
  updateSuccess: storeState.chart.updateSuccess,
  datasets: storeState.chart.datasets,
  dynamicForms: storeState.chart.dynamicForms,
  sharingModes: storeState.sharingMode.entities
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  getDataSets,
  getDynamicForm,
  getSharingModes,
  resetSharingMode,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartUpdate);
