import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  FETCH_SHARING_MODES: 'sharing-mode/FETCH_SHARING_MODES',
  RESET: 'sharing-mode/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  entities: [] as ReadonlyArray<any>
};

export type SharingModeState = Readonly<typeof initialState>;

// Reducer

export default (state: SharingModeState = initialState, action): SharingModeState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SHARING_MODES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SHARING_MODES):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SHARING_MODES):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiSearchSharingModes = 'api/_search/sharing-modes';

// Actions
export const getSharingModes: ICrudSearchAction<any> = query => ({
  type: ACTION_TYPES.FETCH_SHARING_MODES,
  payload: axios.get<any>(`${apiSearchSharingModes}?query=${query}`)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
