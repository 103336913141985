import React from 'react';
import { Switch } from 'react-router-dom';

// tslint:disable-next-line:no-unused-variable
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TaskExecution from './task-execution';
import CMSTask from './cms-task';
import FileImportTask from './file-import-task';
import Chart from './chart';
import DocMetas from './doc-metas';
import RoleGroups from './role-groups';
/* jhipster-needle-add-route-import - FrontLineMedia Search will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}/task-execution`} component={TaskExecution} />
      <ErrorBoundaryRoute path={`${match.url}/cms-task`} component={CMSTask} />
      <ErrorBoundaryRoute path={`${match.url}/file-import-task`} component={FileImportTask} />
      <ErrorBoundaryRoute path={`${match.url}/chart`} component={Chart} />
      <ErrorBoundaryRoute path={`${match.url}/doc-metas`} component={DocMetas} />
      <ErrorBoundaryRoute path={`${match.url}/role-groups`} component={RoleGroups} />
      {/* jhipster-needle-add-route-path - FrontLineMedia Search will routes here */}
    </Switch>
  </div>
);

export default Routes;
