import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from '../header-components';
import { Translate, translate } from 'react-jhipster';

const adminMenuItems = (
  <>
    <DropdownItem tag={Link} to="/entity/cms-task">
      <Translate contentKey="global.menu.admin.cmsTasks">CMS Tasks</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/task-execution">
      <Translate contentKey="global.menu.admin.taskExecutions">Task Executions</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/file-import-task">
      <Translate contentKey="global.menu.admin.fileImportTask">File Import Tasks</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/chart">
      <Translate contentKey="global.menu.admin.charts">Charts</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/entity/role-groups">
      <Translate contentKey="global.menu.admin.roleGroups">Role Groups</Translate>
    </DropdownItem>
    {/* jhipster-needle-add-element-to-admin-menu - FrontLineMedia Search will add entities to the admin menu here
    <DropdownItem tag={Link} to="/admin/logs">
      <FontAwesomeIcon icon="tasks" fixedWidth /> <Translate contentKey="global.menu.admin.logs">Logs</Translate>
    </DropdownItem>
    */}
  </>
);

const swaggerItem = (
  <DropdownItem tag={Link} to="/admin/docs">
    <FontAwesomeIcon icon="book" fixedWidth /> <Translate contentKey="global.menu.admin.apidocs">API</Translate>
  </DropdownItem>
);

export const AdminMenu = ({ showSwagger }) => (
  <NavDropdown icon="user-plus" name={translate('global.menu.admin.main')} style={{ width: '140%' }} id="admin-menu">
    {adminMenuItems}
    {showSwagger && swaggerItem}
  </NavDropdown>
);

export default AdminMenu;
