import './home.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Button, Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { getSession, login } from 'app/shared/reducers/authentication';
import { toast } from 'react-toastify';

import axios from 'axios';

import LoginModal from '../login/login-modal';

export interface IHomeProp extends StateProps, DispatchProps {}

export interface IHomeState {
  lchart: string;
  lfileImport: string;
  lconfluence: string;
  ljira: string;
  showLoginModal: boolean;
  pendingUrl: string;
  pendingRoleUser: string;
}

export class Home extends React.Component<IHomeProp, IHomeState> {
  constructor(props) {
    super(props);

    this.state = {
      lchart: '#',
      lfileImport: '#',
      lconfluence: '#',
      ljira: '#',
      showLoginModal: false,
      pendingUrl: '#',
      pendingRoleUser: ''
    };
  }

  componentDidMount() {
    this.props.getSession();

    if (this.props.location.state && this.props.location.state.fromLogout) {
      window.location.reload();
    }

    this.uniformizeGridCellHeight();
  }

  uniformizeGridCellHeight() {
    const gridCells = ReactDOM.findDOMNode(this).getElementsByClassName('grid-cell');

    let maxHeight = gridCells[0].offsetHeight;

    for (let i = 1; i < gridCells.length; i++) {
      const gridCellHeight = gridCells[i].offsetHeight;

      if (maxHeight < gridCellHeight) {
        maxHeight = gridCellHeight;
      }
    }

    for (const gridCell of gridCells) {
      gridCell.style.height = maxHeight + 'px';
    }
  }

  _goTo = (url, roleUser, apiUrl) => {
    const { account, isAuthenticated } = this.props;

    if (isAuthenticated) {
      axios.get(`/api/url/${apiUrl}`).then(responseUrl => {
        const urlData = responseUrl.data;

        if (urlData && urlData.trim().length > 0) {
          const neWindow = window.open();
          neWindow.location = urlData;
        } else {
          // neWindow.close();

          toast.error(translate(`global.messages.access.denied`));
        }
      });
    } else {
      this.setState({ showLoginModal: true, pendingUrl: apiUrl, pendingRoleUser: roleUser });
    }
  };

  _linkTojira = () => {
    this._goTo(this.state.ljira, 'jira-users', 'jira');
  };

  _linkToSubmit = () => {
    this._goTo(this.state.lfileImport, 'submit-users', 'fileimport');
  };

  _linkToChart = () => {
    this._goTo(this.state.lchart, 'chart-users', 'chart');
  };

  _linkToConfluence = () => {
    this._goTo(this.state.lconfluence, 'confluence-users', 'confluence');
  };

  handleLogin = (username, password, rememberMe = false) => {
    if (username === '' || password === '') {
      return;
    }

    this.props.login(username, password, rememberMe).then(response => {
      this.props.getSession().then(sessionResponse => {
        const { isAuthenticated } = this.props;
        const { pendingUrl, pendingRoleUser } = this.state;

        if (isAuthenticated) {
          this.setState({ showLoginModal: false }, () => {
            axios.get(`/api/url/${pendingUrl}`).then(responseUrl => {
              const url = responseUrl.data;

              if (url && url.trim().length > 0) {
                const neWindow = window.open();
                neWindow.location = url;
              } else {
                //  neWindow.close();

                toast.error(translate(`global.messages.access.denied`));
              }
            });
          });
        } else {
          neWindow.close();
        }
      });
    });
  };

  handleCloseLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  render() {
    const { showLoginModal } = this.state;
    const { account } = this.props;

    return (
      <div>
        <img className="absolute-l-neuron" src="content/images/eletroneuron.png" />

        <img className="absolute-r-neuron" src="content/images/eletroneuron.png" />

        <div className="header-bar">
          <img className="neuron" src="content/images/eletroneuron.png" />
          <img className="header-title" src="content/images/new_title.png" />
          <img className="neuron inverse" src="content/images/eletroneuron.png" />
        </div>

        <div>
          <div className="home-header">
            <Translate contentKey="home.information.header" />
          </div>
        </div>

        <div>
          <div className="grid-container">
            <div className="grid-cell primary" onClick={this._linkToConfluence}>
              <div className="grid-title">Espace de travail</div>

              <div className="grid-content">
                <ul>
                  <li>informations & données marché</li>
                  <li>communautés d'experts</li>
                  <li>outils de production & d'analyse</li>
                  <li>import & analyse de données</li>
                </ul>
              </div>
            </div>

            <div className="grid-cell secondary" onClick={this._linkTojira}>
              <div className="grid-title">Missions mind Research</div>

              <div className="grid-content">
                <ul>
                  <li>Brief & missions mind research</li>
                </ul>
              </div>
            </div>

            <div className="grid-cell third" onClick={this._linkToChart}>
              <div className="grid-title">Création de graphiques</div>

              <div className="grid-content">
                <ul>
                  <li>En cours de développement</li>
                </ul>
              </div>

              {/*<div className="not-developped"/>*/}
            </div>

            <div className="grid-cell fourth" onClick={this._linkToSubmit}>
              <div className="grid-title">Import & analyse de données</div>

              <div className="grid-content">
                <ul>
                  <li>En cours de développement</li>
                </ul>
              </div>

              {/*<div className="not-developped"/>*/}
            </div>
          </div>
        </div>

        <LoginModal
          showModal={showLoginModal}
          handleLogin={this.handleLogin}
          handleClose={this.handleCloseLoginModal}
          loginError={this.props.loginError}
        />
      </div>
    );
  }
}

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  loginError: storeState.authentication.loginError
});

const mapDispatchToProps = { getSession, login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
/*
<p>
  <Translate contentKey="home.question">If you have any question on FrontLineMedia Search:</Translate>
</p>

<ul>
  <li>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <Translate contentKey="home.link.homepage">FrontLineMedia Search homepage</Translate>
    </a>
  </li>
  <li>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <Translate contentKey="home.link.stackoverflow">FrontLineMedia Search on Stack Overflow</Translate>
    </a>
  </li>
  <li>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <Translate contentKey="home.link.bugtracker">FrontLineMedia Search bug tracker</Translate>
    </a>
  </li>
  <li>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <Translate contentKey="home.link.chat">FrontLineMedia Search public chat room</Translate>
    </a>
  </li>
  <li>
    <a href="#" target="_blank" rel="noopener noreferrer">
      <Translate contentKey="home.link.follow">follow @java_hipster on Twitter</Translate>
    </a>
  </li>
</ul>

<p>
  <Translate contentKey="home.like">If you like FrontLineMedia Search, do not forget to give us a star on</Translate>{' '}
  <a href="#" target="_blank" rel="noopener noreferrer">
    Github
  </a>
  !
</p>
*/
