import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './task-execution.reducer';
import { ITaskExecution } from 'app/shared/model/task-execution.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ITaskExecutionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ITaskExecutionUpdateState {
  isNew: boolean;
}

export class TaskExecutionUpdate extends React.Component<ITaskExecutionUpdateProps, ITaskExecutionUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    values.executionStart = convertDateTimeToServer(values.executionStart);
    values.executionEnd = convertDateTimeToServer(values.executionEnd);

    if (errors.length === 0) {
      const { taskExecutionEntity } = this.props;
      const entity = {
        ...taskExecutionEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/task-execution');
  };

  render() {
    const { taskExecutionEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div className="padding-wrapper light-bg">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="frontlinemediaApp.taskExecution.home.createOrEditLabel">
              <Translate contentKey="frontlinemediaApp.taskExecution.home.createOrEditLabel">Create or edit a TaskExecution</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : taskExecutionEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="task-execution-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="taskIdLabel" for="taskId">
                    <Translate contentKey="frontlinemediaApp.taskExecution.taskId">Task Id</Translate>
                  </Label>
                  <AvField id="task-execution-taskId" type="text" name="taskId" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="description">
                    <Translate contentKey="frontlinemediaApp.taskExecution.description">Description</Translate>
                  </Label>
                  <AvField id="task-execution-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="executionStartLabel" for="executionStart">
                    <Translate contentKey="frontlinemediaApp.taskExecution.executionStart">Execution Start</Translate>
                  </Label>
                  <AvInput
                    id="task-execution-executionStart"
                    type="datetime-local"
                    className="form-control"
                    name="executionStart"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.taskExecutionEntity.executionStart)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="executionEndLabel" for="executionEnd">
                    <Translate contentKey="frontlinemediaApp.taskExecution.executionEnd">Execution End</Translate>
                  </Label>
                  <AvInput
                    id="task-execution-executionEnd"
                    type="datetime-local"
                    className="form-control"
                    name="executionEnd"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.taskExecutionEntity.executionEnd)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="durationInSecondsLabel" for="durationInSeconds">
                    <Translate contentKey="frontlinemediaApp.taskExecution.durationInSeconds">Duration In Seconds</Translate>
                  </Label>
                  <AvField id="task-execution-durationInSeconds" type="string" className="form-control" name="durationInSeconds" />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel">
                    <Translate contentKey="frontlinemediaApp.taskExecution.status">Status</Translate>
                  </Label>
                  <AvInput
                    id="task-execution-status"
                    type="select"
                    className="form-control"
                    name="status"
                    value={(!isNew && taskExecutionEntity.status) || 'Running'}
                  >
                    <option value="Running">
                      <Translate contentKey="frontlinemediaApp.TaskExecutionStatus.Running" />
                    </option>
                    <option value="Ended">
                      <Translate contentKey="frontlinemediaApp.TaskExecutionStatus.Ended" />
                    </option>
                    <option value="Cancelled">
                      <Translate contentKey="frontlinemediaApp.TaskExecutionStatus.Cancelled" />
                    </option>
                    <option value="Idle">
                      <Translate contentKey="frontlinemediaApp.TaskExecutionStatus.Idle" />
                    </option>
                    <option value="Enqueued">
                      <Translate contentKey="frontlinemediaApp.TaskExecutionStatus.Enqueued" />
                    </option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="statusMessageLabel" for="statusMessage">
                    <Translate contentKey="frontlinemediaApp.taskExecution.statusMessage">Status Message</Translate>
                  </Label>
                  <AvField id="task-execution-statusMessage" type="text" name="statusMessage" />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/task-execution" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  taskExecutionEntity: storeState.taskExecution.entity,
  loading: storeState.taskExecution.loading,
  updating: storeState.taskExecution.updating,
  updateSuccess: storeState.taskExecution.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskExecutionUpdate);
