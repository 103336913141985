import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import cMSTask, {
  CMSTaskState
} from 'app/entities/cms-task/cms-task.reducer';
// prettier-ignore
import taskExecution, {
  TaskExecutionState
} from 'app/entities/task-execution/task-execution.reducer';
// prettier-ignore
import fileImportTask, {
  FileImportTaskState
} from 'app/entities/file-import-task/file-import-task.reducer';
// prettier-ignore
import chart, {
  ChartState
} from 'app/entities/chart/chart.reducer';

import sharingMode, { SharingModeState } from 'app/entities/sharing-mode/sharing-mode.reducer';
// prettier-ignore
import docMetas, {
  DocMetasState
} from 'app/entities/doc-metas/doc-metas.reducer';
// prettier-ignore
// prettier-ignore
import roleGroups, {
  RoleGroupsState
} from 'app/entities/role-groups/role-groups.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly cMSTask: CMSTaskState;
  readonly taskExecution: TaskExecutionState;
  readonly fileImportTask: FileImportTaskState;
  readonly chart: ChartState;
  readonly sharingMode: SharingModeState;
  readonly docMetas: DocMetasState;
  readonly roleGroups: RoleGroupsState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  cMSTask,
  taskExecution,
  fileImportTask,
  chart,
  docMetas,
  sharingMode,
  roleGroups,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});

export default rootReducer;
