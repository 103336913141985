import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './task-execution.reducer';
import { ITaskExecution } from 'app/shared/model/task-execution.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ITaskExecutionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class TaskExecutionDetail extends React.Component<ITaskExecutionDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { taskExecutionEntity } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="frontlinemediaApp.taskExecution.detail.title">TaskExecution</Translate> [
              <b>{taskExecutionEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="taskId">
                  <Translate contentKey="frontlinemediaApp.taskExecution.taskId">Task Id</Translate>
                </span>
              </dt>
              <dd>{taskExecutionEntity.taskId}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="frontlinemediaApp.taskExecution.description">Description</Translate>
                </span>
              </dt>
              <dd>{taskExecutionEntity.description}</dd>
              <dt>
                <span id="executionStart">
                  <Translate contentKey="frontlinemediaApp.taskExecution.executionStart">Execution Start</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={taskExecutionEntity.executionStart} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="executionEnd">
                  <Translate contentKey="frontlinemediaApp.taskExecution.executionEnd">Execution End</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={taskExecutionEntity.executionEnd} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="durationInSeconds">
                  <Translate contentKey="frontlinemediaApp.taskExecution.durationInSeconds">Duration In Seconds</Translate>
                </span>
              </dt>
              <dd>{taskExecutionEntity.durationInSeconds}</dd>
              <dt>
                <span id="status">
                  <Translate contentKey="frontlinemediaApp.taskExecution.status">Status</Translate>
                </span>
              </dt>
              <dd>{taskExecutionEntity.status}</dd>
              <dt>
                <span id="statusMessage">
                  <Translate contentKey="frontlinemediaApp.taskExecution.statusMessage">Status Message</Translate>
                </span>
              </dt>
              <dd>{taskExecutionEntity.statusMessage}</dd>
            </dl>
            <Button tag={Link} to="/entity/task-execution" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/entity/task-execution/${taskExecutionEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ taskExecution }: IRootState) => ({
  taskExecutionEntity: taskExecution.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskExecutionDetail);
