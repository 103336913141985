export const enum Period {
  SECONDS = 'SECONDS',
  HOURS = 'HOURS',
  DAYS = 'DAYS'
}

export interface ICMSTask {
  id?: number;
  scheduledCrontab?: string;
  description?: string;
  historyPeriodNumber?: number;
  historyPeriodUnit?: Period;
  activate?: boolean;
  whereClause?: string;
}

export const defaultValue: Readonly<ICMSTask> = {
  activate: false
};
