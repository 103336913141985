import { Moment } from 'moment';

export const enum ChartType {
  Pie = 'Pie',
  Bar = 'Bar',
  Map = 'Map'
}

export interface IChart {
  id?: number;
  indexName?: string;
  kibanaDocumentId?: string;
  lastModificationDate?: Moment;
  lastRefreshDate?: Moment;
  user?: string;
  imageContentContentType?: string;
  imageContent?: any;
  type?: ChartType;
  params?: string;
  privateMode?: boolean;
  url?: string;
  extra?: string;
}

export interface IDataSet {
  id?: string;
  name?: string;
}

export const defaultValue: Readonly<IChart> = {
  privateMode: false
};
