export interface IFileImportTask {
  id?: number;
  overridenIndexName?: string;
  overridenUser?: string;
  contentContentType?: string;
  content?: any;
  params?: string;
  fileName?: string;
}

export const defaultValue: Readonly<IFileImportTask> = {};
