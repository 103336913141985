import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './role-groups.reducer';
import { IRoleGroups } from 'app/shared/model/role-groups.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

import { Dropdown } from 'semantic-ui-react';
// tslint:disable-next-line:no-submodule-imports
import 'semantic-ui-css/semantic.min.css';

import axios from 'axios';

export interface IRoleGroupsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IRoleGroupsUpdateState {
  isNew: boolean;
  selectedGroups: string[];
  groupsSelection: sting[];
}

export class RoleGroupsUpdate extends React.Component<IRoleGroupsUpdateProps, IRoleGroupsUpdateState> {
  groupApiUrl = `/api/user-groups`;

  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      selectedGroups: [],
      groupsSelection: []
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    this.getGroupsSelection();

    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id).then(response => {
        const roleGroupsEntity = response.value.data;

        if (roleGroupsEntity.groups) {
          const selectedGroups = roleGroupsEntity.groups.split(',');
          this.setState({ selectedGroups });
        }
      });
    }
  }

  getGroupsSelection = () => {
    axios.get(this.groupApiUrl).then(response => {
      const groupsSelection = response.data;

      this.setState({
        groupsSelection: groupsSelection.map(groupName => ({
          key: groupName,
          text: groupName,
          value: groupName
        }))
      });
    });
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { roleGroupsEntity } = this.props;
      const entity = {
        ...roleGroupsEntity,
        ...values,
        groups: this.state.selectedGroups.join(',')
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/role-groups');
  };

  handleGroupsChange = (e, { searchQuery, value }) => {
    this.setState({ selectedGroups: value });
  };

  render() {
    const { roleGroupsEntity, loading, updating } = this.props;
    const { isNew, selectedGroups, groupsSelection } = this.state;

    return (
      <div className="padding-wrapper light-bg">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="frontlinemediaApp.roleGroups.home.createOrEditLabel">
              <Translate contentKey="frontlinemediaApp.roleGroups.home.createOrEditLabel">Create or edit a RoleGroups</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : roleGroupsEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="role-groups-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="roleLabel" for="role">
                    <Translate contentKey="frontlinemediaApp.roleGroups.role">Role</Translate>
                  </Label>
                  <AvField id="role-groups-role" type="text" name="role" readOnly />
                </AvGroup>
                <AvGroup>
                  <Label id="groupsLabel" for="groups">
                    <Translate contentKey="frontlinemediaApp.roleGroups.groups">Groups</Translate>
                  </Label>
                  <Dropdown
                    placeholder={translate('frontlinemediaApp.roleGroups.groups')}
                    fluid
                    multiple
                    search
                    selection
                    closeOnChange
                    options={groupsSelection}
                    onChange={this.handleGroupsChange}
                    value={selectedGroups}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="activateLabel" check>
                    <AvInput id="role-groups-activate" type="checkbox" className="form-control" name="activate" />
                    <Translate contentKey="frontlinemediaApp.roleGroups.activate">Activate</Translate>
                  </Label>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/role-groups" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  roleGroupsEntity: storeState.roleGroups.entity,
  loading: storeState.roleGroups.loading,
  updating: storeState.roleGroups.updating,
  updateSuccess: storeState.roleGroups.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleGroupsUpdate);
