import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CMSTask from './cms-task';
import CMSTaskDetail from './cms-task-detail';
import CMSTaskUpdate from './cms-task-update';
import CMSTaskDeleteDialog from './cms-task-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CMSTaskUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CMSTaskUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CMSTaskDetail} />
      <ErrorBoundaryRoute path={match.url} component={CMSTask} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CMSTaskDeleteDialog} />
  </>
);

export default Routes;
