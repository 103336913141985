import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './cms-task.reducer';
import { ICMSTask } from 'app/shared/model/cms-task.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICMSTaskUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICMSTaskUpdateState {
  isNew: boolean;
}

export class CMSTaskUpdate extends React.Component<ICMSTaskUpdateProps, ICMSTaskUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { cMSTaskEntity } = this.props;
      const entity = {
        ...cMSTaskEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/cms-task');
  };

  render() {
    const { cMSTaskEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div className="padding-wrapper light-bg">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="frontlinemediaApp.cMSTask.home.createOrEditLabel">
              <Translate contentKey="frontlinemediaApp.cMSTask.home.createOrEditLabel">Create or edit a CMSTask</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : cMSTaskEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="cms-task-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="scheduledCrontabLabel" for="scheduledCrontab">
                    <Translate contentKey="frontlinemediaApp.cMSTask.scheduledCrontab">Scheduled Crontab</Translate>
                  </Label>
                  <AvField id="cms-task-scheduledCrontab" type="text" name="scheduledCrontab" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="description">
                    <Translate contentKey="frontlinemediaApp.cMSTask.description">Description</Translate>
                  </Label>
                  <AvField id="cms-task-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="historyPeriodNumberLabel" for="historyPeriodNumber">
                    <Translate contentKey="frontlinemediaApp.cMSTask.historyPeriodNumber">History Period Number</Translate>
                  </Label>
                  <AvField id="cms-task-historyPeriodNumber" type="string" className="form-control" name="historyPeriodNumber" />
                </AvGroup>
                <AvGroup>
                  <Label id="historyPeriodUnitLabel">
                    <Translate contentKey="frontlinemediaApp.cMSTask.historyPeriodUnit">History Period Unit</Translate>
                  </Label>
                  <AvInput
                    id="cms-task-historyPeriodUnit"
                    type="select"
                    className="form-control"
                    name="historyPeriodUnit"
                    value={(!isNew && cMSTaskEntity.historyPeriodUnit) || 'SECONDS'}
                  >
                    <option value="SECONDS">
                      <Translate contentKey="frontlinemediaApp.Period.SECONDS" />
                    </option>
                    <option value="HOURS">
                      <Translate contentKey="frontlinemediaApp.Period.HOURS" />
                    </option>
                    <option value="DAYS">
                      <Translate contentKey="frontlinemediaApp.Period.DAYS" />
                    </option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="activateLabel" check>
                    <AvInput id="cms-task-activate" type="checkbox" className="form-control" name="activate" />
                    <Translate contentKey="frontlinemediaApp.cMSTask.activate">Activate</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="whereClauseLabel" for="whereClause">
                    <Translate contentKey="frontlinemediaApp.cMSTask.whereClause">Where Clause</Translate>
                  </Label>
                  <AvField id="cms-task-whereClause" type="text" name="whereClause" />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/cms-task" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="frontlinemediaApp.cMSTask.save_and_schedule">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  cMSTaskEntity: storeState.cMSTask.entity,
  loading: storeState.cMSTask.loading,
  updating: storeState.cMSTask.updating,
  updateSuccess: storeState.cMSTask.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMSTaskUpdate);
