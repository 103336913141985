import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import {
  Translate,
  translate,
  ICrudSearchAction,
  ICrudGetAllAction,
  TextFormat,
  getSortState,
  IPaginationBaseState,
  getPaginationItemsNumber,
  JhiPagination
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, extractExcel } from './doc-metas.reducer';
import { IDocMetas } from 'app/shared/model/doc-metas.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { Collapse } from 'react-collapse';

import './doc-metas.css';

export interface IDocMetasProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IDocMetasState extends IPaginationBaseState {
  search: string;
  docMetaData: ReadonlyArray<IDocMetas>;
}

export class DocMetas extends React.Component<IDocMetasProps, IDocMetasState> {
  state: IDocMetasState = {
    search: '',
    docMetaData: [],
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  search = () => {
    this.setState({ activePage: 1 }, () => {
      const { activePage, itemsPerPage, sort, order, search } = this.state;
      this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
    });
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  setInitialDocMet = docMetasList => {
    this.setState({
      docMetaData: docMetasList
    });
  };

  clear = () => {
    this.setState({ search: '', activePage: 1 }, () => {
      this.props.getEntities();
    });
  };

  handleSearch = event => this.setState({ search: event.target.value });

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order, search } = this.state;
    if (search) {
      this.props.getSearchEntities(search, activePage - 1, itemsPerPage, `${sort},${order}`);
    } else {
      this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  docMetasIsOpen = docMetas => {
    let docMetaData = this.state.docMetaData;
    const findResult = docMetaData.indexOf(docMetas);
    if (findResult !== -1) {
      docMetaData[findResult]['isOpened'] = !docMetaData[findResult]['isOpened'];
    }

    docMetaData = docMetaData;

    this.setState({ docMetaData });
  };

  getExcel = () => {
    this.props.extractExcel({ fileName: this.state.search });
  };

  render() {
    const { docMetasList, match, totalItems } = this.props;
    const { docMetaData } = this.state;
    if (docMetaData.length === 0 && docMetasList.length > 0) {
      this.setInitialDocMet(docMetasList);
    }

    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col sm="12">
            <AvForm onSubmit={this.search}>
              <AvGroup>
                <InputGroup>
                  <AvInput
                    type="text"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearch}
                    onKeyPress={this.onKeyPress}
                    placeholder={translate('frontlinemediaApp.docMetas.home.search')}
                  />
                  <Button className="input-group-addon">
                    <FontAwesomeIcon icon="search" />
                  </Button>
                  <Button type="reset" className="input-group-addon" onClick={this.clear}>
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </InputGroup>
              </AvGroup>
            </AvForm>
          </Col>
        </Row>
        <div className="table-responsive">
          <Table responsive className="table-layout table-bordered">
            <thead>
              <tr>
                <th className="hand" onClick={this.sort('fileName')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.fileName">File Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('date')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.date">Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('user')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('persons')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.persons">Persons</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('countries')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.countries">Countries</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('organisations')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.organisations">Organisations</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={this.sort('url')}>
                  <Translate contentKey="frontlinemediaApp.docMetas.url">Url</Translate> <FontAwesomeIcon icon="sort" />
                </th>
              </tr>
            </thead>
            <tbody>
              {docMetaData.map((docMetas, i) => (
                <tr key={`entity-${i}`}>
                  <td className="wordBreak">
                    <div className="displayInline">
                      <input type="checkbox" checked={docMetas['isOpened']} onChange={e => this.docMetasIsOpen(docMetas)} />
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>{docMetas.fileName}</span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                  <td className="wordBreak">
                    <div>
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>
                            <TextFormat type="date" value={docMetas.date} format={APP_DATE_FORMAT} />
                          </span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                  <td className="wordBreak">
                    <div>
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>{docMetas.user}</span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                  <td className="wordBreak">
                    <div>
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>{docMetas.persons}</span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                  <td className="wordBreak">
                    <div>
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>{docMetas.countries}</span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                  <td className="wordBreak">
                    <div>
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>{docMetas.organisations}</span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                  <td className="wordBreak">
                    <div>
                      <Collapse isOpened={docMetas['isOpened']}>
                        <div>
                          <span>{docMetas.url}</span>
                        </div>
                      </Collapse>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row className="justify-content-center">
          <JhiPagination
            items={getPaginationItemsNumber(totalItems, this.state.itemsPerPage)}
            activePage={this.state.activePage}
            onSelect={this.handlePagination}
            maxButtons={5}
          />
        </Row>
        <Row className="justify-content-center">
          <Button onClick={this.getExcel}>
            <Translate contentKey="frontlinemediaApp.docMetas.ExtractAsExcel">Extract as Excel</Translate>
          </Button>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ docMetas }: IRootState) => ({
  docMetasList: docMetas.entities,
  totalItems: docMetas.totalItems
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  extractExcel
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocMetas);
