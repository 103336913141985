import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './doc-metas.reducer';
import { IDocMetas } from 'app/shared/model/doc-metas.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IDocMetasDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class DocMetasDetail extends React.Component<IDocMetasDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { docMetasEntity } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <Row>
          <Col md="8">
            <h2>
              <Translate contentKey="frontlinemediaApp.docMetas.detail.title">DocMetas</Translate> [<b>{docMetasEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="fileName">
                  <Translate contentKey="frontlinemediaApp.docMetas.fileName">File Name</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.fileName}</dd>
              <dt>
                <span id="date">
                  <Translate contentKey="frontlinemediaApp.docMetas.date">Date</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={docMetasEntity.date} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="user">
                  <Translate contentKey="frontlinemediaApp.docMetas.user">User</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.user}</dd>
              <dt>
                <span id="persons">
                  <Translate contentKey="frontlinemediaApp.docMetas.persons">Persons</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.persons}</dd>
              <dt>
                <span id="countries">
                  <Translate contentKey="frontlinemediaApp.docMetas.countries">Countries</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.countries}</dd>
              <dt>
                <span id="organisations">
                  <Translate contentKey="frontlinemediaApp.docMetas.organisations">Organisations</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.organisations}</dd>
              <dt>
                <span id="url">
                  <Translate contentKey="frontlinemediaApp.docMetas.url">Url</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.url}</dd>
              <dt>
                <span id="extras">
                  <Translate contentKey="frontlinemediaApp.docMetas.extras">Extras</Translate>
                </span>
              </dt>
              <dd>{docMetasEntity.extras}</dd>
            </dl>
            <Button tag={Link} to="/entity/doc-metas" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/entity/doc-metas/${docMetasEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ docMetas }: IRootState) => ({
  docMetasEntity: docMetas.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocMetasDetail);
