export interface IRoleGroups {
  id?: number;
  role?: string;
  groups?: string;
  activate?: boolean;
  params?: string;
}

export const defaultValue: Readonly<IRoleGroups> = {
  activate: false
};
