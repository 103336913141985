import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { openFile, byteSize, Translate, ICrudGetAllAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './file-import-task.reducer';
import { IFileImportTask } from 'app/shared/model/file-import-task.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IFileImportTaskProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class FileImportTask extends React.Component<IFileImportTaskProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { fileImportTaskList, match } = this.props;
    return (
      <div className="padding-wrapper light-bg">
        <h2 id="file-import-task-heading">
          <Translate contentKey="frontlinemediaApp.fileImportTask.home.title">File Import Tasks</Translate>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="frontlinemediaApp.fileImportTask.home.createLabel">Create new File Import Task</Translate>
          </Link>
        </h2>
        <div className="table-responsive">
          <Table responsive className="table-bordered">
            <thead>
              <tr>
                <th>
                  <Translate contentKey="global.field.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="frontlinemediaApp.fileImportTask.overridenIndexName">Overriden Index Name</Translate>
                </th>
                <th>
                  <Translate contentKey="frontlinemediaApp.fileImportTask.overridenUser">Overriden User</Translate>
                </th>
                <th>
                  <Translate contentKey="frontlinemediaApp.fileImportTask.content">Content</Translate>
                </th>
                <th>
                  <Translate contentKey="frontlinemediaApp.fileImportTask.params">Params</Translate>
                </th>
                <th>
                  <Translate contentKey="frontlinemediaApp.fileImportTask.fileName">File Name</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {fileImportTaskList.map((fileImportTask, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${fileImportTask.id}`} color="link" size="sm">
                      {fileImportTask.id}
                    </Button>
                  </td>
                  <td>{fileImportTask.overridenIndexName}</td>
                  <td>{fileImportTask.overridenUser}</td>
                  <td>
                    {fileImportTask.content ? (
                      <div>
                        <a onClick={openFile(fileImportTask.contentContentType, fileImportTask.content)}>
                          <Translate contentKey="entity.action.open">Open</Translate>
                          &nbsp;
                        </a>
                        <span>
                          {fileImportTask.contentContentType}, {byteSize(fileImportTask.content)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>{fileImportTask.params}</td>
                  <td>{fileImportTask.fileName}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${fileImportTask.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${fileImportTask.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${fileImportTask.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ fileImportTask }: IRootState) => ({
  fileImportTaskList: fileImportTask.entities
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileImportTask);
